import React, { useEffect, useState, useRef, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Switch,
  Tooltip,
  ConfigProvider,
  Radio,
} from "antd";
import SurveyTractionService from "../service/surveyTransactionService";
import {
  EditTwoTone,
  EyeTwoTone,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import IconEdit from "../assets/icon/icon_edit";
import IconUnlock from "../assets/icon/icon_unlock";
import IconArrowLeft from "../assets/icon/arrow_left";
import IconLogo from "../assets/icon/icon_logo";
import Question from "./Question";

const surveyTractionService = new SurveyTractionService();
export const DetailAppContext = createContext(null);

const SurveyTransactionDetail = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(1);
    const { Search, TextArea } = Input;
    const passData = useLocation();
    const [title, setTitle] = useState();
    const [createDate, setCreateDate] = useState();
    const [agentFullName, setAgentFullName] = useState();
    const [customerFullName, setCustomerFullName] = useState();
    const [surveyChannelName, setSurveyChannelName] = useState();
    const [version, setVersion] = useState();
    const [response, setResponse] = useState();
    const [comment, setComment] = useState();
    let questionOption = {options:[]};
    const [questionState, setQuestionState] = useState(questionOption);
    console.log(passData);

    function initData(response){
        setComment(response.data.additionalComment);
        setTitle(response.data.surveyForm.name);
        setCreateDate(response.data.createdDateDisplay);
        setAgentFullName(response.data.agentFullName);
        setCustomerFullName(response.data.customerFullName);
        setSurveyChannelName(response.data.surveyChannelName);
        setVersion(response.data.version);
    }

    function fetchData(){
        surveyTractionService.getSurveyDetail(passData.state.id).then((response) => {
          if(response.data != undefined){
            // setData(response.data.items);
            console.log(response);
            setResponse(response);
            initData(response);
          }
          // if (response.data.objectValue) setDataList(response.data.objectValue);
          // console.log("ddddd", response.data.objectValue);
        }).catch(async (err) => {
            console.log("err", err);
          });
      }

      useEffect(() => {
        fetchData();
        //   }, [JSON.stringify(tableParams)]);
      }, []);

    const onChange = (e) => {
        console.log("radio checked", e.target.value);
        setValue(e.target.value);
    };

    

    return (
      <ConfigProvider
        theme={{
          components: {
            // Radio:{
            //     dotColorDisabled: "#1677ff",
            //     buttonCheckedBgDisabled:"#FF7F07",
            // },
            // Input: {
            //   activeBorderColor: "#1677ff",
            // },
          },
        }}
      >
        <DetailAppContext.Provider value={{ questionState, setQuestionState }}>
          <div className="w-full">
          
            <div className="flex flex-row">
              <IconArrowLeft
                style={{ marginRight: 20 }}
                onClick={() => {
                  navigate(-1);
                }}
                twoToneColor="#98A2B3"
                onMouseEnter={() => {
                  // alert("I am an alert box!");
                }}
              />
              <label style={{ fontSize: 24, fontWeight: 600 }}>
                Detail Survey
              </label>
            </div>
            <div
                className="w-[85%]"
              style={{
                backgroundColor: "#fff",
                padding: "15px 0px 8px 0px",
                marginBottom: 15,
                marginLeft: 85,
                marginRight: 85,
                marginTop: 30,
                borderRadius: 10,
              }}
            >
              <p className="text-lg font-semibold ms-7">Information</p>
              <div className="flex flex-row">
                <div className="flex-col w-2/4">
                  <p
                    className="text-base font-light ms-7 mt-4"
                    style={{
                      color: "#667085",
                    }}
                  >
                    Title
                  </p>
                  <p
                    className="ms-7 mt-1"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {title}
                  </p>
                </div>
                <div className="flex-col w-2/4">
                  <p
                    className="text-base font-light ms-7 mt-4"
                    style={{
                      color: "#667085",
                    }}
                  >
                    Create Date
                  </p>
                  <p
                    className="ms-7 mt-1"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {createDate}
                  </p>
                </div>
              </div>

              <div className="flex flex-row">
                <div className="flex-col w-2/4">
                  <p
                    className="text-base font-light ms-7 mt-4"
                    style={{
                      color: "#667085",
                    }}
                  >
                    Agent Name
                  </p>
                  <p
                    className="ms-7 mt-1"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {agentFullName}
                  </p>
                </div>
                <div className="flex-col w-2/4">
                  <p
                    className="text-base font-light ms-7 mt-4"
                    style={{
                      color: "#667085",
                    }}
                  >
                    Customer Name
                  </p>
                  <p
                    className="ms-7 mt-1"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {customerFullName}
                  </p>
                </div>
              </div>

              <div className="flex flex-row mb-4">
                <div className="flex-col w-2/4">
                  <p
                    className="text-base font-light ms-7 mt-4"
                    style={{
                      color: "#667085",
                    }}
                  >
                    Channel
                  </p>
                  <p
                    className="ms-7 mt-1"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {surveyChannelName}
                  </p>
                </div>
                <div className="flex-col w-2/4">
                  <p
                    className="text-base font-light ms-7 mt-4"
                    style={{
                      color: "#667085",
                    }}
                  >
                    Version
                  </p>
                  <p
                    className="ms-7 mt-1"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {version}
                  </p>
                </div>
              </div>
            </div>

            <div
              className="mx-auto w-[85%] flex justify-start"
              style={{
                backgroundColor: "#fff",
                padding: "15px 0px 8px 0px",
                border: "1px solid #D0D5DD",
                marginBottom: 15,
                marginLeft: 85,
                marginRight: 85,
                borderRadius: 10,
                minHeight: 580,
              }}
            >
              {/* #F8F8F8 */}

              <div className="w-full pointer-events-none">
                <Row>
                  <Col span={12} style={{ padding: "5px 15px" }}>
                    <div className="mt-3 ms-3">
                      {/* <IconLogo /> */}
                      <img width="50%" height="25%" src="https://samsunglife.co.th/wp-content/uploads/2023/07/Logo-Samsung-Life-Insurance-Eng-no-clear-space.png"></img>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingLeft: "15px" }}>
                  <div className="mt-0 ms-3 me-3" style={{ marginTop: 0, fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538", }} dangerouslySetInnerHTML={{__html: response !== undefined ? response.data.surveyForm.title: ""}}>
                      {/* <label
                        className="mt-8 ms-3"
                        style={{
                          fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538",
                        }}
                      >
                        Call Center Feedback Survey
                      </label> */}
                    </div>
                    {/* <div style={{ marginTop: 10 }}>
                      <label
                        className="mt-8 ms-3"
                        style={{
                          fontWeight: 700,
                          fontSize: 24,
                          color: "#1C2538",
                        }}
                      >
                        Call Center Feedback Survey
                      </label>
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        // marginLeft: "-12px",
                        // margin: "10px 0px",
                        borderBottom: "2px solid #D0D0D0",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={22} style={{ paddingLeft: "15px" }}>
                  <div style={{ marginTop: 0, fontWeight: 400,
                          fontSize: 14,
                          color: "#667085", }} className="mt-0 ms-3 me-3" dangerouslySetInnerHTML={{__html: response !== undefined ? response.data.surveyForm.description: ""}}>
                            {/* <div dangerouslySetInnerHTML={{__html: response !== undefined ? response.data.surveyForm.description: ""}}></div> */}
                      {/* <label
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#667085",
                        }}
                      >
                        Thank you for choosing. Your feedback will help us
                        improve our work and our service standard, so we would
                        greatly appreciate you filling out this form. Thank you
                      </label> */}
                    </div>
                  </Col>
                  <Col span={2}>
                    <div style={{ marginTop: 0 }}></div>
                  </Col>
                </Row>
                <Row className="mt-0 ms-7 me-7">
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        // marginLeft: "-12px",
                        margin: "10px 0px",
                        borderBottom: "2px solid #ECF1F6",
                      }}
                    />
                  </Col>
                </Row>
                {/* ----------------------- */}
                <Question response={response} isDetailMode={true} isTouchable={false} page="surveyTransactionDetail" />
                {/* ----------------------- */}

                <Row className="ms-3 me-3">
                  <Col span={24} style={{ padding: 15 }}>
                    <div style={{ marginBottom: 0, marginTop: 10 }}>
                      <label style={{ fontWeight: 500, color: "#1C2538" }}>
                        Additional Comments:
                      </label>
                    </div>
                    {/* <Form.Item
                  //  label="Name"
                  name="comments"
                  colon={false}
                  defaultValue={comment}
                  value={comment}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "This information is required.",
                  //   },
                  // ]}
                >
                  
                </Form.Item> */}
                    <TextArea
                      className="mt-2"
                      defaultValue={comment}
                      value={comment}
                    />
                  </Col>
                </Row>

                {/* <div className="flex flex-row justify-center mb-8">
              <Button
                className="w-28"
                style={{
                  marginRight: 12,
                  backgroundColor: "#FFFFFF",
                  color: "#344054",
                  border: "1px solid #D9D9D9",
                }}
                onClick={() => {
                  navigate("/survey-form-detail");
                }}
                // type="primary"
                shape="default"
              >
                Cancel
              </Button>
              <Button
                className="w-28"
                style={{
                  marginRight: 10,
                  backgroundColor: "#FF7F07",
                  color: "#FFFFFF",
                  border: "2px solid #FF7F07",
                }}
                onClick={() => {
                  navigate("/survey-form-detail");
                }}
                // type="primary"
                shape="default"
              >
                Save
              </Button>
            </div> */}
              </div>
            </div>
          </div>
        </DetailAppContext.Provider>
      </ConfigProvider>
    );
}

export default SurveyTransactionDetail;