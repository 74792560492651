import axios from "axios";
import environment from "../environment/environment";

const url = environment.SERVICE_URL_DASHBOARD;

    const getSurveyForm = async (dateType, interval) => {
        try {
        const response = await axios.get(`${url}/survey-form`, {
            params: {
                dateType: dateType,
                interval: interval
            }
        });
        console.log('Response from getSurveyForm:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error fetching data getSurveyForm error:', error);
        throw error;
        }
    };

    const getNonVoiceReport = async (channelId) => {
        try {
        const response = await axios.get(`${url}/non-voice-report`, {
            params: {
                ChannelId: channelId,
            }
        });
        console.log('Response from getNonVoiceReport:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error fetching data getNonVoiceReport error:', error);
        throw error;
        }
    };

    const getVoiceReport = async (channelId) => {
        try {
        const response = await axios.get(`${url}/voice-report`, {
            params: {
                ChannelId: channelId,
            }
        });
        console.log('Response from getVoiceReport:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error fetching data getVoiceReport error:', error);
        throw error;
        }
    };

export{
    getSurveyForm,
    getNonVoiceReport,
    getVoiceReport,
}