import React from "react";
import { Table, Typography, Space, Tag,Progress,Flex } from 'antd';
import { max } from "moment";

const VoiceReport = (props) => {

  //console.log("VoiceReport page >>>> props = ",props)

  //const dataSource = props.voiceReportList

  let dataSource = [];
  if(props.voiceReportList){
    props.voiceReportList.map((data,index) => {
      data.rowNum = (index+1)
      dataSource.push(data)
  });
  }

  const columns = [
    {
      title: '',
      dataIndex: 'rowNum',
      key: 'rowNum',
      width: '5%',
      render: (text, record) => (
        <svg width="35" height="35">
          <circle r="12" fill="#67A6FF" cx="20" cy="22" ></circle>
          <text x="20" y="27" text-anchor="middle" fill="#fff">{`${record.rowNum}`}</text>
        </svg>
        ),     
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      width: '20%',
    },
    {
      title: 'Survey Type',
      dataIndex: 'surveyType',
      key: 'surveyType',
      width: '20%',
    },
    {
      title: 'Call Survey',
      dataIndex: 'callSurvey',
      key: 'callSurvey',
      width: '10%',
    },
    {
      title: 'Incomplete / Complete',
      dataIndex: 'incompleted',
      key: 'incompleted',
      width: '35%',
      render: (text, record) => (
          <div>
            <Flex vertical gap="small" >
              <Progress format={() => `${record.incompleted}`} strokeWidth={20} strokeColor={"#A9CAF9"} trailColor={"transparent"} style={{width:((record.incompleted/record.callSurvey) * 100) <= 10 ? '10%' : ((record.incompleted/record.callSurvey) * 100)+'%'}} percent={100} /> 
              <Progress format={() => `${record.completed}`} strokeWidth={20} strokeColor={"#1677FF"} trailColor={"transparent"} style={{width:((record.completed/record.callSurvey) * 100) <= 10 ? '10%' : ((record.completed/record.callSurvey) * 100)+'%'}} percent={100} /> 
            </Flex>
          </div>
          ),     
    },
  ];

  return (
    <div>
      <Table className="nonVoiceReportTable" dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

export default VoiceReport;
