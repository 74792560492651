import React from "react";
import { Table, Typography, Space, Tag,Progress,ProgressProps,Flex } from 'antd';

const NonVoiceReport = (props) => {

  //console.log("NonVoiceReport page >>>> props = ",props)

  //const dataSource = props.nonVoiceReportList

  let dataSource = [];
  if(props.nonVoiceReportList){
    props.nonVoiceReportList.map((data,index) => {
      data.rowNum = (index+1)
      dataSource.push(data)
  });
  }
       

  const columns = [
    {
      title: '',
      dataIndex: 'rowNum',
      key: 'rowNum',
      width: '5%',
      render: (text, record) => (
        <svg width="35" height="35">
          <circle r="12" fill="#FFA24B" cx="20" cy="22" ></circle>
          <text x="20" y="27" text-anchor="middle" fill="#fff">{`${record.rowNum}`}</text>
        </svg>
        ),     
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      width: '20%',
    },
    {
      title: 'Survey form Title',
      dataIndex: 'surveyFormTitle',
      key: 'surveyFormTitle',
      width: '30%',
    },
    {
      title: 'Total',
      dataIndex: 'averageScore',
      key: 'averageScore',
      width: '35%',
      render: (averageScore) => (
                            <Flex vertical gap="small" >
                                <Progress format={() => `${averageScore}`} strokeWidth={20} strokeColor={{ from: '#FF7F07', to: '#FFB46E' }} trailColor={"#FFE9D4"}  percent={(averageScore/5) * 100} /> 
                            </Flex>
                        ),
    },
  ];

  return (
    <div>
      <Table className="nonVoiceReportTable" dataSource={dataSource} columns={columns} pagination={false} showSorterTooltip={true} />
    </div>
  );
};

export default NonVoiceReport;
